import $ from 'jquery';

const $doc = $(document);
const nav = $('.nav--main__container');
const hamburger = $('.js-hamburger');
const button = $('.js-nav');
const openSvg = $('#open-nav');
const closeSvg = $('#close-nav');
const $mobileNavSub = $('.menu-item-has-children');
$mobileNavSub.children('a').wrap("<div class=link-wrap></div>");
$mobileNavSub.children('.link-wrap').append( '<span><svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.907 2.28656C17.9596 2.22921 17.9596 2.14116 17.907 2.08381L16.1068 0.120565C16.0473 0.0557349 15.9451 0.0557351 15.8857 0.120565L9.11055 7.50907C9.05111 7.57389 8.94889 7.57389 8.88944 7.50906L2.11433 0.120565C2.05488 0.0557346 1.95267 0.0557351 1.89322 0.120565L0.0929613 2.08381C0.040369 2.14116 0.0403691 2.22921 0.0929613 2.28656L8.88944 11.8794C8.94889 11.9443 9.05111 11.9443 9.11056 11.8794L17.907 2.28656Z" fill="#221F20"/></svg></span>');

function showNav(event) {
  event.preventDefault();
  nav.toggleClass('is-active');
  if ( nav.hasClass('is-active')) {
    button.attr('aria-expanded', 'true');
    openSvg.hide();
    closeSvg.show();
  } else {
    button.attr('aria-expanded', 'false');
    openSvg.show();
    closeSvg.hide();
  }
}

function showNavMobile(event) {
  event.preventDefault();
  nav.toggleClass('is-active');
  hamburger.toggleClass('is-active');
  if ( nav.hasClass('is-active')) {
    hamburger.attr('aria-expanded', 'true');
  } else {
    hamburger.attr('aria-expanded', 'false');
  }
}

function initMenu() {
  if ($(window).width() < 925) {

    if (!$('a[href*="info"]').hasClass('is-active')) {
      $('.nav--main__info').css('display', '');
      $('a[href*="navigation"]').addClass('is-active');
    }

    $('.js-menu').on('click', function(event) {
    event.preventDefault();
      if (!$(this).hasClass('is-active')) {
        $(this).toggleClass('is-active');
        $(this).siblings().toggleClass('is-active');
        const hrefThis = $(this).attr('href');
        const hrefSibling = $(this).siblings().attr('href');
        $('.nav--main__' + hrefSibling).fadeOut( 'fast', function() {
          $('.nav--main__' + hrefThis).fadeIn();
          $('.nav--main__wrapper').toggleClass('is-info');
        });
      }
    });

  } else {
    $('.nav--main__info').css('display', 'flex');
    $('.nav--main__navigation').show();
    $('a[href*="info"]').removeClass('is-active');
    $('.nav--main__wrapper').removeClass('is-info');
  }

  $(window).on( 'scroll', function() {
    const el = $('.main').children().first(),
    hH = el.outerHeight(),
    wS = $(this).scrollTop();
    
    if ( $(window).width() < 925 ) {
      if ( wS > hH ){
        $('.logo-symbol').addClass('is-active');
        $('.logo-desktop').addClass('not-active');
        $('.js-header').addClass('is-fixed');
        $('.contact-button').addClass('is-active');
      } else {
        $('.logo-symbol').removeClass('is-active');
        $('.logo-desktop').removeClass('not-active');
        $('.js-header').removeClass('is-fixed');
        $('.contact-button').removeClass('is-active');
      }
    }
  });

}

function navDropdowns() {
  const submenu = $(this).children('.sub-menu');
  const link = $(this).find('a');
  const span = $(this).find('span');
  submenu.slideToggle(500);
  link.toggleClass('is-active');
  span.toggleClass('is-active');
  $('.sub-menu').not(submenu).slideUp(500);
  $(this).attr('aria-expanded',
    $(this).attr('aria-expanded')=='false' ? 'true' : 'false'
  );
  $mobileNavSub.not($(this)).attr('aria-expanded', 'false');
  $('.menu-item-has-children a').not(link).removeClass('is-active');
  $('span').not(span).removeClass('is-active');
}

export default function initNav(){
  $doc.on('click', '.js-nav', showNav );
  $doc.on('click', '.js-hamburger', showNavMobile );
  $(document).ready( initMenu );
  $(window).on( 'resize', initMenu );
  $doc.on('click', '.menu-item-has-children', navDropdowns );

  /* $doc.on('keydown', function(event) {
    if ( nav.hasClass('is-active') && (event.key == "Escape") ) {
      hamburger.attr('aria-expanded', 'false')
      nav.toggleClass('is-active')
      hamburger.toggleClass('is-active')
    }
  })
  $doc.on('focusin', function (event) {
    var $target = $(event.target);
    if (!$target.closest('.js-header-wrapper').length) {
      if ( nav.hasClass('is-active') ) {
        hamburger.attr('aria-expanded', 'false')
        nav.toggleClass('is-active')
        hamburger.toggleClass('is-active')
      }
    }
  }) */
}
