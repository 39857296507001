import $ from 'jquery';
const $doc = $(document);

const tabContent = $('.news__posts--wrapper');
const loader = $('.news__loader');

function toggleClass(e) {
  e.preventDefault();
  if(!$(this).hasClass('active')) {
    $(this).addClass('active')
    $('.js-news-tabs').not(this).removeClass('active');
  }
  initTabs(e,1);
}

function initTabs(e,page) {
  e.preventDefault();
  const category = $('.js-news-tabs.active').attr('data-slug');
  const data = {
    action : "get_news_tabs",
    cat : category,
    page : page
  }
  toggleTabs(data);
}

function toggleTabs(data) {
  tabContent.empty();
  loader.addClass('loading')
  return $.ajax({
    url : ajax_object.ajax_url,
    data : data,
    success : function(response) {
      tabContent.html(response);
      loader.removeClass('loading');
    }
  });
}

function initPagination(e) {
  e.preventDefault();
  //const page = $(this).clone().children().remove().end().text();
  let page = parseInt($(this).html());
  let currentPage = parseInt($('.page-numbers.current').html());
  if($(this).hasClass('next')) {
    page = currentPage + 1;
  }
  if($(this).hasClass('prev')) {
    page = currentPage - 1;
  }
  if($(this).hasClass('current')) {
    return;
  }
  initTabs(e,page);
  $('html, body').animate({
    scrollTop: $('.news__posts').offset().top
  }, 100);
}

export default function initNewsTabs() {
  $doc.on('click', '.pagination a', initPagination )
  $doc.on('click', '.js-news-tabs', toggleClass )
  /* $(document).ready(function(){
    const data = {
      action : "get_news_tabs",
      cat : 'new',
      page : 1
    }
    toggleTabs(data);
  }); */
  //$doc.on('click', '.js-news-tabs', initTabs )
}
