import 'slick-carousel';
import $ from 'jquery';

function initSlickSlider() {

  const slider = $('.js-application');

  const settings = {
    speed: 400,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    infinite: true,
    arrows: false,
    dots: true,
    mobileFirst: true,
    responsive: [{
      breakpoint: 925,
      settings: "unslick"
    }]
  }

  if (slider.length) {
    slider.slick(settings);
  }

  $(window).on('resize', function() {
    if (!slider.hasClass('slick-initialized')) {
      return slider.slick(settings);
    }
  });

}

export default function initSlick() {
  $(document).ready( initSlickSlider );
}
