import $ from 'jquery'

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function init(){
  var hasCookie = getCookie('privacy-cookies');
  if(!hasCookie) $(".cookies").slideToggle();

  $('.cookies__cta a').on('click', function() {
    $(".cookies").fadeOut();
    setCookie('privacy-cookies', 'true', 1);
  });
}


function initPopup(){
  var popupCookie = getCookie('popup-cookies');
  if(!popupCookie) {
    $('.site-popup').delay(500).fadeIn(300);
    //$('html').css('overflow','hidden');
  } 

  $('.site-popup__button').on('click', function() {
    $('.site-popup').fadeOut(100);
    //$('html').css('overflow','auto');
    setCookie('popup-cookies', 'true', 7);
  });
}

export default function initCookies(){
  $(document).ready( init );
  //$(document).ready( initPopup );
}
