import $ from 'jquery';

const $doc = $(document);

function scrollToTop(event) {
  event.preventDefault();
  $('html, body').animate({scrollTop : 0}, 1000);
}

function scrollSection(event) {
  event.preventDefault();
  const section = $(this).parents('section').next();
  if(section.length > 0) {
    $('html, body').animate({
      scrollTop: section.offset().top
    }, 1000);
  }
}

export default function initScrolls() {
  $doc.on('click', '.js-scroll-top', scrollToTop );
  $doc.on('click', '.js-scroll-section', scrollSection );
}
