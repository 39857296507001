import $ from 'jquery'
import magnificPopup from 'magnific-popup'
const  $doc = $(document)

// this is just an example of functionality, opens only one static popup with #popup ID
function openModal(e) {
  e.preventDefault();
  $.magnificPopup.open({
    items: {
      src: '#popup',
      type: 'inline',
    },
    closeBtnInside: true
  });
}

export default function initMagnificPopup() {
  $doc.on('click', '.js-open-modal', openModal );
  $doc.on('click', 'a[href^="#application"]', openModal );
  $('.js-popup').magnificPopup({
    type:'inline',
    midClick: true,
    removalDelay: 300,
    mainClass: 'popup--animate',
    closeBtnInside: true
  });
}
