import $ from 'jquery';
const $doc = $(document);

function openForm(event) {
  event.preventDefault();
  $('.contact-form').addClass('is-active');
}

function closeForm(event) {
  event.preventDefault();
  $('.contact-form').removeClass('is-active');
}

export default function initContactForm() {
  $doc.on('click', 'a[href$="#contact"]', openForm )
  $doc.on('click', '.js-form-close', closeForm )
}
